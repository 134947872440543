import React, { useEffect, useState } from 'react';
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-theme-material-ui';
import moment from 'moment';
import * as API from '../../services/api';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      flexGrow: 1,
    },
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    marginTop: theme.spacing(3),

    '& .MuiButtonBase-root': {
      minWidth: 30,
    },
  },
  badgeYes: {
    display: 'inline-block',
    padding: '3px 5px 1px 5px',
    borderRadius: 4,
    color: 'white',
    background: '#46be8a',
    fontSize: '0.8em',
    letterSpacing: '0.15em',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  badgeNo: {
    display: 'inline-block',
    padding: '3px 5px 1px 5px',
    borderRadius: 4,
    color: 'white',
    background: '#dc4747',
    fontSize: '0.8em',
    letterSpacing: '0.15em',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}));

const perPage = 20;

const formatDate = (date) => {
  return moment(date).format('MMM DD, YYYY hh:mm');
};

export default function CustomersIndex(props) {
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [customersTotal, setCustomersTotal] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    if (!customers.length) {
      API.getAllCustomers(page, perPage).then((data) => {
        if (data) {
          setCustomers(data.customers);
          setCustomersTotal(data.total);
        }
      });
    }
  }, [customers, page]);

  if (!customers.length) {
    return <CircularProgress />;
  }

  const pageCount = Math.ceil(customersTotal / perPage);
  const gotoPage = (nextPage) => {
    setCustomers([]);
    setPage(nextPage);
  };

  const deleteCustomer = async (uuid) => {
    if (window.confirm('Are you sure?')) {
      await API.deleteCustomer(uuid);
      setCustomers([]);
    }
  };

  return (
    <>
      <Paper className={classes.nav}>
        <Typography variant="h6">Signups</Typography>
        <Button color="primary" to="/new_signup">
          New Signup
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Internal ID</TableCell>
              <TableCell>Advertiser Code</TableCell>
              <TableCell>Form Finished</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers &&
              customers.map((customer) => (
                <TableRow key={customer.uuid}>
                  <TableCell>{customer.uuid}</TableCell>
                  <TableCell>{customer.code}</TableCell>
                  <TableCell>
                    <Badge classes={classes} value={customer.formFinished} />
                  </TableCell>
                  <TableCell>{formatDate(customer.createdAt)}</TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      size="small"
                      to={`/signups/${customer.uuid}`}
                    >
                      View
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      to={`/signups/${customer.uuid}/edit`}
                    >
                      Edit
                    </Button>
                    <MaterialButton
                      color="secondary"
                      size="small"
                      onClick={() => deleteCustomer(customer.uuid)}
                    >
                      Delete
                    </MaterialButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCount > 1 && (
        <Paper className={classes.pagination}>
          {[...Array(pageCount)].map((_, i) => (
            <MaterialButton
              key={i}
              size="small"
              color={i + 1 === page ? 'primary' : undefined}
              onClick={() => gotoPage(i + 1)}
            >
              {i + 1}
            </MaterialButton>
          ))}
        </Paper>
      )}
    </>
  );
}

const Badge = (props) => {
  const { value, classes } = props;

  if (value) {
    return <div className={classes.badgeYes}>YES</div>;
  } else {
    return <div className={classes.badgeNo}>NO</div>;
  }
};
