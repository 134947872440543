import React from 'react';
import Layout from '../components/layout/Layout';
import CustomersIndex from '../components/customers/Index';

export default (props) => {
  return (
    <Layout page="signups">
      <CustomersIndex />
    </Layout>
  );
};
